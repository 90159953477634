import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import NavBar from "./NavBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedIconPng from "../../Assets/verified.png";
import BannerImage from "../../Assets/Cover.png";
import { useOutletContext, Outlet } from "react-router-dom";
import LiveFeedFilter from "../Layout/LiveFeedFilter";

const UserProfile = () => {
  const { user, messages } = useOutletContext();
  console.log(user)

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <NavBar user={user} />
      <Grid item xs={12} md={7}>
        <Box sx={{ minHeight: "88vh", margin: "auto", borderRadius: "12px", backgroundColor: "#212330" }}>
          <Box sx={{ position: "relative", width: "100%", textAlign: "center" }}>
            <Box
              sx={{
                backgroundImage: `url(${BannerImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: 200,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bold",
                borderRadius: "12px 12px 0 0"
              }}
            >
            </Box>
            <Avatar
              alt="Max Schmidt"
              src={user.profile_image_url.sharable}
              sx={{
                width: 120,
                height: 120,
                border: "4px solid white",
                position: "absolute",
                top: 140,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
            <Box mt={8}>
              <Typography variant="body1" fontSize="22px" fontWeight="bold" display="flex" alignItems="center" justifyContent="center">
                {user.full_name}
                {user.verified && (
                  <img
                    src={VerifiedIconPng}
                    alt="Verified"
                    style={{ width: "18px", height: "18px", marginLeft: "5px" }}
                  />
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">{user.username}</Typography>
            </Box>
            <Typography variant="body2" sx={{ px: 3, mt: 2 }}>
              {user.bio}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 3,
                mt: 2,
                px: 3,
                fontSize: "0.9rem",
                color: "gray",
              }}
            >
              <span style={{ color: "#fff" }}>
                {user.total_likes} <span style={{ color: "#9098A3" }}>Likes</span>
              </span>
              <span style={{ color: "#fff" }}>
                {user.total_boosts} <span style={{ color: "#9098A3" }}>Radius Reach </span>
              </span>
              <span style={{ color: "#fff" }}>
                {user.emergency_contacts.length} <span style={{ color: "#9098A3" }}>Friends</span>
              </span>
            </Box>
            <IconButton sx={{ position: "absolute", top: 10, left: 10 }}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Divider sx={{ bgcolor: "#EEEEEE", height: 1, mt: "16px", mx: 3 }} />
          <Box m={2}>
            <Outlet context={{ user, messages }} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3} pl={0}>
        <LiveFeedFilter />
      </Grid>
    </Grid>
  );
};

export default UserProfile;
