import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Typography,
  Grid,
  Link,
  Box,
  ImageListItem,
  useMediaQuery,
} from "@mui/material";
import CustomCheckCircleIcon from "./CustomCheckCircleIcon";
import logoLight from "../../Assets/locaided Logo.png";
import logoDark from "../../Assets/locaided Logo dark.png";
import vector from "../../Assets/Vector.png";
import VerifiedIconPng from "../../Assets/verified.png";
import { useTheme } from "../../ThemeContext";

const Home = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { darkMode } = useTheme();

  const targetDate = new Date("2025-03-11T00:00:00Z");
  const now = new Date();
  const initialCountdown = Math.floor((targetDate - now) / 1000);
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  const dayRem = Math.floor(countdown / 86400);
  const hrRem = Math.floor((countdown % 86400) / 3600);
  const minRem = Math.floor((countdown % 3600) / 60);
  const secRem = countdown % 60;

  useEffect(() => {
    const token = localStorage.getItem("access-token");
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/login");
  };

  const smallScreen = useMediaQuery("(max-width:720px)");
  const mediumScreen = useMediaQuery(
    "(min-width:721px) and (max-width:1366px)"
  );
  const largeScreen = useMediaQuery("(min-width:1367px)");

  return (
    <Container
      maxWidth="100%"
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: smallScreen ? "column" : "row",
          alignItems: "center",
          width: smallScreen ? "100%" : "65%",
          maxWidth: largeScreen ? "1366px" : "100%",
          gap: "20px",
        }}
      >
        <Box
          style={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <ImageListItem>
            <img
              src={darkMode ? logoLight : logoDark}
              alt="Locaided Logo"
              onClick={()=>navigate("/dashboard")}
              style={{
                width: "90%",
                maxWidth: smallScreen ? "75px" : "60%",
                height: "auto",
                cursor:"pointer"
              }}
            />
          </ImageListItem>
        </Box>
        <Box
          style={{
            flex: 1,
            textAlign: "left",
            padding: smallScreen ? "16px" : "0",
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{
                  marginBottom: "-10px",
                  fontSize: smallScreen
                    ? "1.75rem"
                    : mediumScreen
                    ? "2rem"
                    : "3rem",
                }}
              >
                Join the Countdown.
              </Typography>
              <img
                src={vector}
                alt="Line"
                style={{
                  width: "17%",
                  height: "auto",
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                fontWeight="bold"
                style={{
                  fontSize: smallScreen
                    ? "1.05rem"
                    : mediumScreen
                    ? "1.2rem"
                    : "1.75rem",
                }}
              >
                Sign up and Be the First to Explore Locaided on the Web and the
                App Stores!
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Work Sans",
                  fontSize: smallScreen
                    ? "0.95rem"
                    : mediumScreen
                    ? "1rem"
                    : "1.5rem",
                }}
              >
                <CustomCheckCircleIcon style={{ marginRight: "8px" }} />
                Connect Differently, Discover Deeply.
              </Typography>
              <span
                style={{
                  display: "block",
                  fontFamily: "Work Sans",
                  fontSize: smallScreen
                    ? "0.75rem"
                    : mediumScreen
                    ? "0.75rem"
                    : "1.15rem",
                  marginLeft: "2rem",
                }}
              >
                Experience the power of a hyper-local social network with
                Locaided.
              </span>
            </Grid>

            <Grid item>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{
                  display: "flex",
                  fontFamily: "Work Sans",
                  alignItems: "center",
                  fontSize: smallScreen
                    ? "0.95rem"
                    : mediumScreen
                    ? "1rem"
                    : "1.5rem",
                }}
              >
                <CustomCheckCircleIcon style={{ marginRight: "8px" }} />
                Fake News Ends Here!
              </Typography>
              <span
                style={{
                  display: "block",
                  fontFamily: "Work Sans",
                  fontSize: smallScreen
                    ? "0.75rem"
                    : mediumScreen
                    ? "0.75rem"
                    : "1.15rem",
                  marginLeft: "2rem",
                }}
              >
                If it's Locaided, it's Real.
              </span>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Work Sans",
                  fontSize: smallScreen
                    ? "0.95rem"
                    : mediumScreen
                    ? "1rem"
                    : "1.5rem",
                }}
              >
                <CustomCheckCircleIcon style={{ marginRight: "8px" }} />
                Community-Powered Content Moderation
              </Typography>
              <span
                style={{
                  display: "block",
                  fontFamily: "Work Sans",
                  fontSize: smallScreen
                    ? "0.75rem"
                    : mediumScreen
                    ? "0.75rem"
                    : "1.15rem",
                  marginLeft: "2rem",
                }}
              >
                Community Votes Determine Content Visibility. Together, we shape
                a trustworthy space.
              </span>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{
                  display: "flex",
                  fontFamily: "Work Sans",
                  alignItems: "center",
                  fontSize: smallScreen
                    ? "1rem"
                    : mediumScreen
                    ? "1rem"
                    : "1.5rem",
                }}
              >
                <img
                  src={VerifiedIconPng}
                  alt="Verified"
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />
                Free Premium Month
              </Typography>
              <span
                style={{
                  display: "block",
                  fontFamily: "Work Sans",
                  fontSize: smallScreen
                    ? "0.75rem"
                    : mediumScreen
                    ? "0.75rem"
                    : "1.15rem",
                  marginLeft: "2rem",
                }}
              >
                Act fast! Sign up before the countdown hits zero and secure a
                free premium month!
              </span>
            </Grid>
          </Grid>
          <Box m={2}>
            {isLoggedIn ? (
              <Button
                variant="contained"
                style={{
                  borderRadius: "50px",
                  fontFamily: "Urbanist",
                  backgroundColor: "#fff",
                  color: "black",
                  height: "2.5rem",
                  width: smallScreen ? "100%" : "70%",
                }}
                onClick={handleLogout}
              >
                <b>Logout</b>
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                  fontFamily: "Urbanist",
                  color: "black",
                  height: smallScreen
                    ? "2.5rem"
                    : mediumScreen
                    ? "2.5rem"
                    : "2.5rem",
                  width: smallScreen ? "100%" : "70%",
                }}
                onClick={() => navigate("/signup")}
              >
                <b>Sign Up</b>
              </Button>
            )}
          </Box>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              fontFamily: "Bebas Neue",
              marginLeft: smallScreen
                ? ""
                : mediumScreen
                ? "1.25rem"
                : "1.25rem",
              textAlign: smallScreen ? "center" : "left",
              fontSize: smallScreen
                ? "2rem"
                : mediumScreen
                ? "2.5rem"
                : "3.5rem",
            }}
          >
            {dayRem} DAYS - {`${hrRem}:${minRem}:${secRem}`}
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent={smallScreen ? "center" : "left"}
            style={{
              marginLeft: smallScreen ? "" : mediumScreen ? "0.5rem" : "0.5rem",
            }}
          >
            <Grid item>
              <Link href="#" color="inherit" style={{ textDecoration: "none" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Helvetica Neue",
                    fontSize: smallScreen
                      ? "0.75rem"
                      : mediumScreen
                      ? "0.75rem"
                      : "1rem",
                  }}
                >
                  Terms and Conditions
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" color="inherit" style={{ textDecoration: "none" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Helvetica Neue",
                    fontSize: smallScreen
                      ? "0.75rem"
                      : mediumScreen
                      ? "0.75rem"
                      : "1rem",
                  }}
                >
                  Privacy Policy
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" color="inherit" style={{ textDecoration: "none" }}>
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: "Helvetica Neue",
                    fontSize: smallScreen
                      ? "0.75rem"
                      : mediumScreen
                      ? "0.75rem"
                      : "1rem",
                  }}
                >
                  Imprint
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
