import React, { useState, useEffect } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import {
  Avatar,
  Grid,
  CardMedia,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
  Box,
  Alert,
  Snackbar,
  DialogTitle,
} from "@mui/material";
import LiveFeedFilter from "../Layout/LiveFeedFilter";
import NavBar from "./Navbar";
import Logo from "../../Assets/white logo.png"
import { ReactComponent as SearchIcon } from '../../Assets/search.svg';
import { ReactComponent as FilterIcon } from '../../Assets/filter.svg';
import { ReactComponent as LikeIcon } from "../../Assets/like.svg";
import { ReactComponent as DislikeIcon } from "../../Assets/dislike.svg";
import { ReactComponent as LocationIcon } from '../../Assets/Location.svg';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';
import { ReactComponent as DotsIcon } from "../../Assets/dots.svg";
import { ReactComponent as BackwardIcon } from "../../Assets/backward.svg";
import { ReactComponent as ForwardIcon } from "../../Assets/forward.svg";
import { ReactComponent as PlayIcon } from "../../Assets/play.svg";
import { ReactComponent as PauseIcon } from "../../Assets/pause.svg";

const LiveEvidences = () => {
  const { user } = useOutletContext();
  const [evidences, setEvidences] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedEvidenceIndex, setSelectedEvidenceIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [location, setLocation] = useState({
    lat: "",
    long: "",
  })

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation((prevFormData) => ({
            ...prevFormData,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          }));
        },
        (error) => {
          setError("Error getting location. Please enable location services.");
          setOpenSnackbar(true);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    let timer;
    if (isPlaying && selectedEvidenceIndex !== null) {
      timer = setInterval(() => {
        setSelectedEvidenceIndex((prevIndex) => {
          if (prevIndex < evidences.length - 1) {
            return prevIndex + 1;
          } else {
            setIsPlaying(false);
            return prevIndex;
          }
        });
      }, 5000);
    }
    return () => clearInterval(timer);
  }, [isPlaying, selectedEvidenceIndex, evidences.length]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const fetchEvidence = async () => {
      setLoading(true)
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      try {
        const response = await axios.get("https://api.locaided.com/v1/evidences/", {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
          params: {
            lat: location.lat,
            long: location.long,
          },
        });
        const formattedEvidences = response.data.data.evidences?.flatMap(ev => ev.evidences_array) || [];
        setEvidences(formattedEvidences);
      } catch (error) {
        console.error("Error fetching Evidences:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvidence();
  }, [location]);

  const handleOpen = (index) => {
    setSelectedEvidenceIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEvidenceIndex(null);
  };

  const handleNext = () => {
    if (selectedEvidenceIndex < evidences.length - 1) {
      setSelectedEvidenceIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedEvidenceIndex > 0) {
      setSelectedEvidenceIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handlePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-start">
        <NavBar user={user} />
        <Grid item xs={12} md={7} >
          <Box bgcolor="#212330" p={2} sx={{ borderRadius: "12px", minHeight: "88vh" }}>
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Search by User, Image ID, or Location."
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchIcon width="16px" height="16px" />
                    </IconButton>
                  ),
                  endAdornment: (
                    <IconButton>
                      <FilterIcon width="20px" height="20px" />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            {loading ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <CircularProgress size={40} sx={{ color: "#FD445D" }} />
                <Typography variant="contain" style={{ marginTop: "18px" }}>
                  Loading...
                </Typography>
              </Box>
            ) : (
              <Grid container mt={2} justifyContent="flex-start" sx={{ borderRadius: "10px", overflow: "hidden" }}>
                {evidences.length > 0 ? (
                  evidences.map((evidence, index) => (
                    <Grid item xs={6} sm={6} md={3} lg={3} key={index}>
                      <CardMedia
                        component="img"
                        image={evidence.file_url?.sharable || "/placeholder.jpg"}
                        alt="Evidence"
                        sx={{ height: "100%", cursor: "pointer" }}
                        onClick={() => handleOpen(index)}
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography variant="h6" color="#fff" justifyContent="center">
                    No Evidences Yet
                  </Typography>
                )}
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <LiveFeedFilter />
        </Grid>
      </Grid>
      <Dialog fullScreen open={open} onClose={handleClose} sx={{ backgroundColor: "#171923" }}>
        <DialogTitle sx={{ padding: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#171923", px: 5, pt: 6 }}>
            <img
              src={Logo}
              alt="Full View"
              style={{ width: "8%", }}
            />
            <IconButton onClick={handleClose}>
              <CloseIcon width="20px" height="20px" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end", backgroundColor: "#171923" }}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={3} justifyContent="center" mr={4} mb={8}>
            {selectedEvidenceIndex !== null && (
              <Avatar
                src={evidences[selectedEvidenceIndex]?.user_details?.profile_image?.sharable}
                alt="User"
                style={{ width: "45px", height: "45px", border: "3px solid #000" }}
              />
            )}

            <Typography variant="caption" sx={{ color: "#fff", bgcolor: "#000", borderRadius: "50px", px: 0.75, py: 1.5 }} justifyContent="center">
              22:34
            </Typography>
            <IconButton component="span" sx={{ bgcolor: "#000", p: 1.4, color: "#fff" }}>
              <DotsIcon width="20px" height="20px" />
            </IconButton>
          </Box>
          <Box>
            {selectedEvidenceIndex !== null && (
              <img
                src={evidences[selectedEvidenceIndex]?.file_url?.sharable}
                alt="Evidence"
                style={{ maxWidth: "100%", maxHeight: "80vh", height: "80vh", borderRadius: "10px" }}
              />
            )}
            <Box display="flex" alignItems="center" justifyContent="space-evenly" mt={2}>
              <IconButton component="span" sx={{ bgcolor: "#fff","&:hover": {backgroundColor: "#BEC0C9",}}} onClick={handlePrevious} disabled={selectedEvidenceIndex === 0}>
                <BackwardIcon width="20px" height="20px" />
              </IconButton>
              {isPlaying ?
              <IconButton component="span" onClick={handlePlay} sx={{ bgcolor: "#fff","&:hover": {backgroundColor: "#BEC0C9",}}}>
                <PauseIcon width="20px" height="20px" />
              </IconButton> :
              <IconButton component="span" onClick={handlePlay}>
                <PlayIcon width="20px" height="20px" />
              </IconButton>}
              <IconButton component="span" sx={{ bgcolor: "#fff","&:hover": {backgroundColor: "#BEC0C9",}}} onClick={handleNext} disabled={selectedEvidenceIndex === evidences.length - 1}>
                <ForwardIcon width="20px" height="20px" />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" gap={3} justifyContent="center" ml={4} mb={8}>
            <IconButton component="span" sx={{ bgcolor: "#000", p: 1.4, color: "#fff" }}>
              <LikeIcon width="20px" height="20px" />
            </IconButton>
            <IconButton component="span" sx={{ bgcolor: "#000", p: 1.4, color: "#fff" }}>
              <DislikeIcon width="20px" height="20px" />
            </IconButton>
            <IconButton component="span" sx={{ bgcolor: "#000", p: 1.4, color: "#fff" }}>
              <LocationIcon width="20px" height="20px" />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LiveEvidences;
