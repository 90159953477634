/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Avatar,
  TextField,
  Paper,
  Typography,
  IconButton,
  Box,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  // Tooltip,
  CircularProgress,
  Popover,
  Modal,
  Tabs,
  Tab,
} from "@mui/material";
// import Cable from "../../Config/Cable"
import { useParams, useNavigate } from "react-router-dom";
import Picker from "emoji-picker-react";
import { ReactComponent as GalleryIcon } from "../../Assets/gallery.svg";
import { ReactComponent as VideoIcon } from "../../Assets/video-square.svg";
import { ReactComponent as MicIcon } from "../../Assets/Voice.svg";
import { ReactComponent as SendIcon } from "../../Assets/Send.svg";
import { ReactComponent as ReactionIcon } from "../../Assets/Reaction.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/delete.svg";
import { ReactComponent as SettingIcon } from "../../Assets/setting2.svg";
import { ReactComponent as ArrowIcon } from "../../Assets/Arrow.svg";
import { ReactComponent as StickerIcon } from "../../Assets/sticker.svg";
import Sticker1 from "../../Assets/Stickers/Beans/1.png"
import Sticker2 from "../../Assets/Stickers/Beans/2.png"
import Sticker3 from "../../Assets/Stickers/Beans/3.png"
import Sticker4 from "../../Assets/Stickers/Beans/4.png"
import Sticker5 from "../../Assets/Stickers/Beans/5.png"
import Sticker6 from "../../Assets/Stickers/Beans/6.png"
import Sticker7 from "../../Assets/Stickers/Beans/7.png"
import Sticker8 from "../../Assets/Stickers/Beans/8.png"
import Sticker9 from "../../Assets/Stickers/Beans/9.png"
import bookmarked1 from "../../Assets/Stickers/Bookmarked/bookmarked 1.png"
import bookmarked2 from "../../Assets/Stickers/Bookmarked/bookmarked 2.png"
import bookmarked3 from "../../Assets/Stickers/Bookmarked/bookmarked 3.png"
import bookmarked4 from "../../Assets/Stickers/Bookmarked/bookmarked 4.png"
import bookmarked5 from "../../Assets/Stickers/Bookmarked/bookmarked 5.png"
import bookmarked6 from "../../Assets/Stickers/Bookmarked/bookmarked 6.png"

const stickers = [
  Sticker1,
  Sticker2,
  Sticker3,
  Sticker4,
  Sticker5,
  Sticker6,
  Sticker7,
  Sticker8,
  Sticker9,
];

const bookmarked = [
  bookmarked1,
  bookmarked2,
  bookmarked3,
  bookmarked4,
  bookmarked5,
  bookmarked6,
];

const ChatWindow = () => {
  const { id } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [image, setImage] = useState(null);
  const [audio, setAudio] = useState(null);
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const [deleteId, setDeleteId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSticker, setOpenSticker] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    const savedChat = localStorage.getItem("selectedChat");
    if (savedChat) {
      setSelectedChat(JSON.parse(savedChat));
    }
  }, []);

  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem("selectedChat");
  //     setSelectedChat(null);
  //   };
  // }, [location.pathname]);

  const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleAudioChange = (e) => setAudio(e.target.files[0]);
  const handleVideoChange = (e) => setVideo(e.target.files[0]);

  const fetchMessages = () => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get(
        `https://api.locaided.com/v1/users/${id}/direct_messages`,
        {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        }
      )
      .then((response) => {
        setMessages(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chats:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMessages();
  }, [id]);

  const formatDate = (dateString) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // eslint-disable-next-line no-unused-vars
    const [weekday, dayMonthYear] = dateString.split(' ');
    const [day, month, year] = dayMonthYear.split('.');
    const messageDate = new Date(`${year}-${month}-${day}`);

    if (
      messageDate.getDate() === today.getDate() &&
      messageDate.getMonth() === today.getMonth() &&
      messageDate.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    }

    if (
      messageDate.getDate() === yesterday.getDate() &&
      messageDate.getMonth() === yesterday.getMonth() &&
      messageDate.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }

    return dateString;
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleEmojiClick = (emoji) => {
    setDescription((prevDescription) => prevDescription + emoji.emoji);
    setShowEmojiPicker(false);
  };

  const handleEmojiToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setShowEmojiPicker((prev) => !prev);
  };

  const handleClickOpen = (uId, id) => {
    setUserId(uId)
    setDeleteId(id);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");
      const response = await axios.delete(`https://api.locaided.com/v1/users/${userId}/direct_messages/${deleteId}`, {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      });
      fetchMessages();
      handleClose();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || "An error occurred";
      console.log(errorMessage, "error");
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
    setUserId(null);
  };

  const handleSubmit = async () => {
    const url = `https://api.locaided.com/v1/users/${id}/direct_message`;
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    const formData = new FormData();
    formData.append("description", description);
    if (image) formData.append("image", image, image.name);
    if (audio) formData.append("audio", audio, audio.name);
    if (video) formData.append("video", video, video.name);

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(url, formData, {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      }
      );
      fetchMessages();
      setDescription("");
      setImage(null);
      setAudio(null);
      setVideo(null);
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const renderPreview = () => {
    const hasFiles = image || video || audio;

    return (
      hasFiles && (
        <Stack spacing={2} direction="row" sx={{ marginBottom: "1rem", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {image && (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              style={{ maxWidth: "25%", maxHeight: "auto", borderRadius: "8px" }}
            />
          )}
          {video && (
            <video
              controls
              src={URL.createObjectURL(video)}
              style={{ maxWidth: "25%", maxHeight: "auto", borderRadius: "8px" }}
            />
          )}
          {audio && (
            <audio controls src={URL.createObjectURL(audio)} style={{ maxWidth: "50%", maxHeight: "2rem", }} />
          )}
        </Stack>)
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ height: "88vh", display: "flex", flexDirection: "column", backgroundColor: "#212330", borderRadius: "12px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
          <Box gap={1} sx={{ display: "flex", alignItems: "center", padding: "0.5rem 0" }}>
            <IconButton component="span" sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <ArrowIcon width="16px" height="16px" />
            </IconButton>
            <Avatar
              alt={selectedChat.opposite_user_full_name}
              src={selectedChat.opposite_user_profile_image.sharable}
              style={{ width: "32px", height: "32px", }}
            />
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {selectedChat.opposite_user_full_name}
            </Typography>
          </Box>
          <IconButton component="span" sx={{ color: "#fff" }} onClick={() => navigate("../setting")}>
            <SettingIcon width="18px" height="18px" />
          </IconButton>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            p: 2,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {messages.length > 0 ? (messages.map((day, index) => (
            <React.Fragment key={index}>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "#f2f0ef",
                  fontSize: "12px",
                }}
              >
                {formatDate(day.date)}
              </Typography>
              {day.chats_array.map((message, idx) => (
                // <Tooltip title={message.time || ""}>
                <Box key={idx} sx={{
                  position: "relative",
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  justifyContent: message.opposite_user_id === message.receiver_id ? "flex-end" : "flex-start",
                  "&:hover .toolbar": {
                    display: "flex",
                  },
                }}>
                  <Paper
                    sx={{
                      p: 0,
                      mb: 1,
                      bgcolor:
                        message.opposite_user_id === message.receiver_id
                          ? "#EAEAEA"
                          : "#212330",
                      border: "1px solid #E6E6E6",
                      borderRadius:
                        message.opposite_user_id === message.receiver_id
                          ? "16px 0 16px 16px"
                          : "0 16px 16px 16px",
                      color:
                        message.opposite_user_id === message.receiver_id
                          ? "#000"
                          : "#FFF",
                      alignSelf:
                        message.opposite_user_id === message.receiver_id
                          ? "flex-end"
                          : "flex-start",
                      maxWidth: "60%",
                    }}
                  >
                    {message.image_url?.sharable && (
                      <img
                        src={message.image_url.sharable}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          borderRadius: "16px",
                          alignSelf: "center",
                        }}
                      />
                    )}
                    {message.video_url?.sharable && (
                      <video
                        controls
                        src={message.video_url.sharable}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          borderRadius: "16px",
                          alignSelf: "center",
                        }}
                      />
                    )}
                    {message.audio_url?.sharable && (
                      <audio
                        controls
                        src={message.audio_url.sharable}
                        style={{
                          maxWidth: "100%",
                          height: "35px",
                          borderRadius: "16px",
                          alignSelf: "center",
                        }}
                      />
                    )}
                    {!message.description ? (
                      null
                    ) : (
                      <Typography
                        variant="body2"
                        p={1}
                        style={{
                          fontSize: "13px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {message.description}
                      </Typography>
                    )}
                  </Paper>
                  <Box
                    className="toolbar"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: message.opposite_user_id === message.receiver_id ? "60%" : "auto",
                      left: message.opposite_user_id === message.receiver_id ? "auto" : "60%",
                      display: "none",
                      flexDirection: "row",
                      borderRadius: "8px",
                      padding: "4px",
                    }}
                  >
                    <IconButton onClick={handleEmojiToggle} sx={{ color: "#fff" }}>
                      <ReactionIcon width="20px" height="20px" />
                    </IconButton>
                    <IconButton size="small" sx={{ color: "#FFF" }} onClick={() => handleClickOpen(message.sender_id, message.id)}>
                      <DeleteIcon width="20px" height="20px" />
                    </IconButton>
                  </Box>

                </Box>
                // </Tooltip>
              ))}
            </React.Fragment>
          ))
          ) : (
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: "#A6A6A6",
                fontStyle: "italic",
                mt: 2,
              }}
            >
              No messages yet. Start the conversation!
            </Typography>
          )}
          <div ref={messagesEndRef} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
          <Stack direction="row">
            <IconButton sx={{ color: "#fff" }} onClick={() => setOpenSticker(true)}>
              <StickerIcon width="20px" height="20px" />
            </IconButton>

            <label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <IconButton component="span" sx={{ color: "#fff" }}>
                <GalleryIcon width="20px" height="20px" />
              </IconButton>
            </label>

            <label>
              <input
                type="file"
                accept="video/*"
                onChange={handleVideoChange}
                style={{ display: "none" }}
              />
              <IconButton component="span" sx={{ color: "#fff" }}>
                <VideoIcon width="20px" height="20px" />
              </IconButton>
            </label>

            <label>
              <input
                type="file"
                accept="audio/*"
                onChange={handleAudioChange}
                style={{ display: "none" }}
              />
              <IconButton component="span" sx={{ color: "#fff" }}>
                <MicIcon width="20px" height="20px" />
              </IconButton>
            </label>
          </Stack>
          <TextField
            placeholder="Type message..."
            size="small"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            minRows={1}
            maxRows={8}
            sx={{ mr: 2, borderRadius: "15px", fontSize: "12px" }}
            InputProps={{
              sx: { display: "flex", flexDirection: "column", borderRadius: "15px", fontSize: "12px" },
              startAdornment: (
                renderPreview()
              ),
            }}
          />
          <IconButton
            sx={{
              color: "#fff",
              p: 1,
              backgroundColor: "#ff2557",
              "&:hover": {
                backgroundColor: "#ff4b6f",
              },
              "&.Mui-disabled": {
                backgroundColor: "#ff83a3",
              },
            }}
            disabled={!description.trim() && !image && !audio && !video}
            onClick={handleSubmit}
          >
            <SendIcon width="15px" height="15px" />
          </IconButton>

        </Box>
      </Box>
      <Dialog
        open={open}
        sx={{
          maxWidth: "371px",
          margin: "auto",
          "& .MuiPaper-root": {
            backgroundColor: "#212121",
            color: "#fff",
            padding: "15px",
          },
          "& .MuiDialogTitle-root": {
            padding: "5px",
            textAlign: "center",
          },
          "& .MuiDialogContentText-root": {
            color: "#fff",
          },
          "& .MuiDialogContent-root": {
            padding: "5px",
          },
          "& .MuiDialogActions-root": {
            padding: "5px",
          },
          "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are You Sure You Want to Delete this Message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#A6A6A6",
              color: "#fff",
              "&:hover": { backgroundColor: "#B8B8B8" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            sx={{
              backgroundColor: "#ff2557",
              color: "#fff",
              "&:hover": { backgroundColor: "#ff4b6f" },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={showEmojiPicker}
        anchorEl={anchorEl}
        onClose={() => setShowEmojiPicker(false)}
        style={{ paddingBottom: "1px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Picker style={{ borderRadius: "2px" }} theme="dark" reactionsDefaultOpen={true} onReactionClick={handleEmojiClick} />
      </Popover>
      <Modal open={openSticker} onClose={() => setOpenSticker(false)}>
        <Box
          sx={{
            width: 400,
            minWidth: 400,
            bgcolor: "#303343",
            color: "#fff",
            borderRadius: 2,
            p: 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box sx={{bgcolor:"#ECEDF1", color:"#000", borderRadius:"100px", p:"2px"}}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            centered
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{
              minHeight: "unset",
              "& .MuiTabs-flexContainer": {
                justifyContent:"space-between"
              },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                fontSize:"12px",
                borderRadius: "100px",
                transition: "0.3s",
                minHeight: "20px",
                minWidth: "100px",
              },
              "& .Mui-selected": {
                bgcolor: "#FF2E5F",
                color: "#fff !important",
              },
            }}
          >
            <Tab label="GIFS" value={0} />
            <Tab label="Stickers" value={1} />
            <Tab label="Bookmarked" value={2} />
          </Tabs>
          </Box>

          {activeTab === 1 && (
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 1, mt: 2 }}>
              {stickers.map((src, i) => (
                <img key={i} src={src} alt="sticker" style={{ width: "100%", borderRadius: 8 }} />
              ))}
            </Box>
          )}

          {activeTab === 2 && (
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", mt: 2 }}>
              {bookmarked.map((src, i) => (
                <img key={i} src={src} alt="sticker" style={{ width: "100%" }} />
              ))}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ChatWindow;
