import React from "react";
import {
  Toolbar,
  Button,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const TopBar = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isPost = location.pathname === "/dashboard/profile";
  const isEYS = location.pathname === "/dashboard/profile/eys";
  const isSetting = location.pathname === "/dashboard/profile/settings";

  return (
    <Box
      style={{ backgroundColor: "#212330", borderRadius: "12px" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isPost ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/profile")}
          >
            Post
          </Button>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isEYS ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/profile/eys")}
          >
            EYS
          </Button>
          <Button
            variant="text"
            size="small"
            style={{
              borderRadius: "8px",
              backgroundColor: isSetting ? "#FF2557" : "transparent",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/profile/settings")}
          >
            Settings
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar alt="User Avatar" src={user.profile_image_url.sharable} />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography color="#F0F1F5">{user.full_name}</Typography>
            <Typography color="#B3B6C4" style={{ fontSize: "0.65rem" }}>
              {user.username}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </Box>
  );
};

const NavBar = ({ user }) => {
  return (
    <Box sx={{ width: "85%", pt: "1rem", pl: "1rem" }}>
      <TopBar user={user} />
    </Box>
  );
};

export default NavBar;
