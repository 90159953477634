import React from "react";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    Typography,
    Divider,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import pushPng from "../../Assets/Push notification.png";
import { ReactComponent as DotsIcon } from "../../Assets/dots.svg";
import { ReactComponent as LikedIcon } from "../../Assets/Liked.svg";
import { ReactComponent as RepostedIcon } from "../../Assets/reposted.svg";

const AllNotification = () => {
    const { user } = useOutletContext();

    return (
        <>
            <Box sx={{
                pt: 1,
                backgroundColor: "#212330",
                px: 1,
                height: '87vh',
                borderRadius: "10px",
            }}>
                <Typography variant="body2" sx={{ mt: 1, fontSize: "12px", color: "#BEC0C9", textAlign: "center" }}>
                    The central hub for all immediate and important activity alerts.
                </Typography>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={user.profile_image_url.sharable}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}><b>Usman</b> is offering help. Do you want to share your location with him?</Typography>
                                    <Box display="flex" gap={1}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                fontSize: "12px",
                                                backgroundColor: "#FF2557",
                                                "&:hover": {
                                                    backgroundColor: "#FF2557",
                                                },
                                                color: "#fff",
                                            }}>
                                            Accept
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                fontSize: "12px",
                                                backgroundColor: "#FFF",
                                                "&:hover": {
                                                    backgroundColor: "#D0D5DD",
                                                },
                                                color: "#000",
                                            }}>
                                            Decline
                                        </Button>
                                    </Box>
                                </Box>
                            }
                            style={{ fontSize: "16px" }}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#BEC0C9" }}>2m</Typography>
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <DotsIcon width="16px" height="16px" />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <Divider sx={{ bgcolor: "#42465B", height: 1, ml: 8 }} />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={user.profile_image_url.sharable}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}><b>Usman</b> commented on <b>your Post</b></Typography>
                                    <Box display="flex" gap={1} alignItems="flex-start">
                                        <Divider
                                            orientation="vertical"
                                            sx={{ height: 40, width: 4, bgcolor: "#fff" }}
                                        />
                                        <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}>Looks perfect, send it for technical review tomorrow!</Typography>
                                    </Box>
                                </Box>
                            }
                            style={{ fontSize: "16px" }}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#BEC0C9" }}>8h</Typography>
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <DotsIcon width="16px" height="16px" />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <Divider sx={{ bgcolor: "#42465B", height: 1, ml: 8 }} />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={pushPng}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}><b>New Feature Alert!</b></Typography>
                                    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                        <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}>We’re pleased to introduce the latest enhancements in our templating experience.</Typography>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                fontSize: "12px",
                                                backgroundColor: "#FF2557",
                                                "&:hover": {
                                                    backgroundColor: "#FF2557",
                                                },
                                                color: "#fff",
                                            }}>
                                            Try Now
                                        </Button>
                                    </Box>
                                </Box>
                            }
                            style={{ fontSize: "16px" }}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#BEC0C9" }}>14h</Typography>
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <DotsIcon width="16px" height="16px" />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <Divider sx={{ bgcolor: "#42465B", height: 1, ml: 8 }} />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={user.profile_image_url.sharable}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}><b>Usman</b> liked <b>your Post</b></Typography>
                                    <Box display="flex" gap={1} alignItems="flex-start">
                                        <Divider
                                            orientation="vertical"
                                            sx={{ height: 40, width: 4, bgcolor: "#fff" }}
                                        />
                                        <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}>Car troubles in the middle of nowhere, no gas and my spare can is empty...</Typography>
                                    </Box>
                                </Box>
                            }
                            style={{ fontSize: "16px" }}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#BEC0C9" }}>8h</Typography>
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <DotsIcon width="16px" height="16px" />
                            </IconButton>
                            <LikedIcon width="16px" height="16px"/>
                        </Box>
                    </ListItem>
                    <Divider sx={{ bgcolor: "#42465B", height: 1, ml: 8 }} />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={user.profile_image_url.sharable}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}><b>Usman boosted your post,</b> extending its visibility by <b>15 km from her location.</b></Typography>
                                    <Box display="flex" gap={1} alignItems="flex-start">
                                        <Divider
                                            orientation="vertical"
                                            sx={{ height: 40, width: 4, bgcolor: "#fff" }}
                                        />
                                        <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}>Car troubles in the middle of nowhere, no gas and my spare can is empty...</Typography>
                                    </Box>
                                </Box>
                            }
                            style={{ fontSize: "16px" }}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#BEC0C9" }}>8h</Typography>
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <DotsIcon width="16px" height="16px" />
                            </IconButton>
                            <RepostedIcon width="16px" height="16px"/>
                        </Box>
                    </ListItem>
                    <Divider sx={{ bgcolor: "#42465B", height: 1, ml: 8 }} />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={user.profile_image_url.sharable}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}><b>Usman</b> and <b>8 others</b> commented on your post.</Typography>
                                </Box>
                            }
                            style={{ fontSize: "16px" }}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#BEC0C9" }}>15h</Typography>
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <DotsIcon width="16px" height="16px" />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <Divider sx={{ bgcolor: "#42465B", height: 1, ml: 8 }} />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                alt="Profile Picture"
                                src={user.profile_image_url.sharable}
                                style={{ width: "40px", height: "40px" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
                                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#BEC0C9" }}><b>Usman Sajid</b> has <b>shared his/her/their location</b> with you. Do you want to start navigating to them?</Typography>
                                    <Box display="flex" gap={1}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                fontSize: "12px",
                                                backgroundColor: "#2E70E8",
                                                "&:hover": {
                                                    backgroundColor: "#2E70E8",
                                                },
                                                color: "#fff",
                                            }}>
                                            Start Route
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                fontSize: "12px",
                                                backgroundColor: "#FFF",
                                                "&:hover": {
                                                    backgroundColor: "#D0D5DD",
                                                },
                                                color: "#000",
                                            }}>
                                            Decline
                                        </Button>
                                    </Box>
                                </Box>
                            }
                            style={{ fontSize: "16px" }}
                        />
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body2" sx={{ fontSize: "12px", color: "#BEC0C9" }}>2m</Typography>
                            <IconButton component="span" sx={{ color: "#fff" }}>
                                <DotsIcon width="16px" height="16px" />
                            </IconButton>
                        </Box>
                    </ListItem>
                    <Divider sx={{ bgcolor: "#42465B", height: 1, ml: 8 }} />
                </List>
            </Box>
        </>
    );
};

export default AllNotification;
