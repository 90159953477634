import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Avatar,
  TextField,
  IconButton,
  Button,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { ReactComponent as GalleryIcon } from "../../Assets/gallery.svg";
import { ReactComponent as VideoIcon } from "../../Assets/video-square.svg";
import { ReactComponent as MicIcon } from "../../Assets/Voice.svg";
import { ReactComponent as GifIcon } from "../../Assets/Image.svg";

const PostCreate = ({ user }) => {
  const [description, setDescription] = useState("");
  const [showLocation, setShowLocation] = useState(true);
  const [gif, setGif] = useState(null);
  const [image, setImage] = useState(null);
  const [audio, setAudio] = useState(null);
  const [video, setVideo] = useState(null);

  const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleGifChange = (e) => setGif(e.target.files[0]);
  const handleAudioChange = (e) => setAudio(e.target.files[0]);
  const handleVideoChange = (e) => setVideo(e.target.files[0]);
  const handleLocationToggle = (e) => setShowLocation(e.target.checked);

  const handleSubmit = async () => {
    if (!description.trim()) return;

    const url = `https://api.locaided.com/v1/messages`;
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    const formData = new FormData();
    formData.append("message[description]", description);
    formData.append("message[lat]", user.lat);
    formData.append("message[long]", user.long);
    formData.append("message[show_location]", showLocation.toString());

    if (image) formData.append("message[image]", image, image.name);
    if (audio) formData.append("message[audio]", audio, audio.name);
    if (video) formData.append("message[video]", video, video.name);
    if (gif) formData.append("message[gif]", gif, gif.name);

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      });

      setDescription("");
      setShowLocation(true);
      setGif(null);
      setImage(null);
      setAudio(null);
      setVideo(null);
    } catch (error) {
      console.error(
        "Error sending Post:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const renderPreview = () => {
    const hasFiles = image || video || audio || gif;

    return (
      hasFiles && (
        <Stack spacing={2} direction="row" sx={{ marginTop: "2rem", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {image && (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              style={{ maxWidth: "20%", maxHeight: "auto", borderRadius: "8px" }}
            />
          )}
          {video && (
            <video
              controls
              src={URL.createObjectURL(video)}
              style={{ maxWidth: "25%", maxHeight: "auto", borderRadius: "8px" }}
            />
          )}
          {audio && (
            <audio controls src={URL.createObjectURL(audio)} style={{ maxWidth: "50%", maxHeight: "2rem", }} />
          )}
          {gif && (
            <img
              src={URL.createObjectURL(gif)}
              alt="GIF Preview"
              style={{ maxWidth: "20%", maxHeight: "auto", borderRadius: "8px" }}
            />
          )}
        </Stack>)
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "#212330",
        borderRadius: "12px",
        margin: "0 0.5rem",
        padding: "1rem",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar alt="User" src={user.profile_image_url.sharable} />
        <TextField
          placeholder="What’s on your mind?"
          size="small"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          minRows={1}
          maxRows={8}
          sx={{
            backgroundColor: "#2A2D3D",
            lineHeight: "1rem",
            color: "#D7D9E3",
            borderRadius: "5px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "& textarea::placeholder": {
                color: "#D7D9E3",
                fontSize: "0.8rem",
                opacity: 1,
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                width="40%"
              >
                <Typography sx={{ color: "#fff", fontSize: "0.75rem" }}>
                  Add Location
                </Typography>
                <Switch
                  checked={showLocation}
                  onChange={handleLocationToggle}
                  size="small"
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#FF2557",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#FF2557",
                    },
                  }}
                />
              </Stack>
            ),
          }}
        />
      </Stack>
      {renderPreview()}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: "1rem" }}
      >
        <Stack direction="row" ml={6}>
          <label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <IconButton component="span" sx={{ color: "#fff" }}>
              <GalleryIcon width="20px" height="20px" />
            </IconButton>
          </label>

          <label>
            <input
              type="file"
              accept="video/*"
              onChange={handleVideoChange}
              style={{ display: "none" }}
            />
            <IconButton component="span" sx={{ color: "#fff" }}>
              <VideoIcon width="20px" height="20px" />
            </IconButton>
          </label>

          <label>
            <input
              type="file"
              accept="image/gif"
              onChange={handleGifChange}
              style={{ display: "none" }}
            />
            <IconButton component="span" sx={{ color: "#fff" }}>
              <GifIcon width="20px" height="20px" />
            </IconButton>
          </label>

          <label>
            <input
              type="file"
              accept="audio/*"
              onChange={handleAudioChange}
              style={{ display: "none" }}
            />
            <IconButton component="span" sx={{ color: "#fff" }}>
              <MicIcon width="20px" height="20px" />
            </IconButton>
          </label>
        </Stack>

        <Button
          variant="contained" size="small"
          disabled={!description.trim()}
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#FF2557",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#D02047",
            },
            "&.Mui-disabled": {
              backgroundColor: "#FF2557",
              color: "#fff",
            },
          }}
        >
          Create a post
        </Button>
      </Stack>
    </Box>
  );
};

export default PostCreate;
