import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Box, CardMedia, Grid, Typography, CircularProgress, Snackbar } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const Evidences = () => {
  const { user } = useOutletContext();
  const [evidences, setEvidences] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({
    lat: "",
    long: "",
  })

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation((prevFormData) => ({
            ...prevFormData,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          }));
        },
        (error) => {
          setError("Error getting location. Please enable location services.");
          setOpenSnackbar(true);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setOpenSnackbar(true);
    }
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const fetchEvidence = async () => {
      setLoading(true)
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      try {
        const response = await axios.get("https://api.locaided.com/v1/evidences/", {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
          params: {
            lat: location.lat,
            long: location.long,
          },
        });
        const formattedEvidences = response.data.data.evidences?.flatMap(ev => ev.evidences_array) || [];
        setEvidences(formattedEvidences);
      } catch (error) {
        console.error("Error fetching Evidences:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvidence();
  }, [location]);

  if (!user && !evidences) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <CircularProgress size={40} sx={{ color: "#FD445D" }} />
          <Typography variant="contain" style={{ marginTop: "18px" }}>
            Loading...
          </Typography>
        </Box>
      ) : (
        <Grid container mt={2} justifyContent="flex-start" sx={{ borderRadius: "10px", overflow: "hidden" }}>
          {evidences.length > 0 ? (
            evidences.map((evidence, index) => (
              <Grid item xs={6} sm={6} md={3} lg={3} key={index}>
                <CardMedia
                  component="img"
                  image={evidence.file_url?.sharable || "/placeholder.jpg"}
                  alt="Evidence"
                  sx={{
                    height: "100%",
                  }}
                />
              </Grid>
            ))
          ) : (
            <Typography variant="h6" color="#fff" justifyContent="center">
              No Evidences Yet
            </Typography>
          )}
        </Grid>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Evidences;
