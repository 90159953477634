import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from 'moment';
import { useOutletContext } from "react-router-dom";
import {
  Avatar,
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  IconButton,
  TextField,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Alert,
  Snackbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import LiveFeedFilter from "../Layout/LiveFeedFilter";
import NavBar from "./Navbar";
import VerifiedIconPng from "../../Assets/verified.png";
import PostCreate from "./PostCreate";
import html2canvas from "html2canvas";
import { ReactComponent as GifIcon } from "../../Assets/Image.svg";
import { ReactComponent as GalleryIcon } from "../../Assets/gallery.svg";
import { ReactComponent as BoostIcon } from "../../Assets/boost.svg";
import { ReactComponent as MicIcon } from "../../Assets/Mic.svg";
import { ReactComponent as SendIcon } from "../../Assets/Send.svg";
import { ReactComponent as LikeIcon } from "../../Assets/like.svg";
import { ReactComponent as RepostIcon } from "../../Assets/repost.svg";
import { ReactComponent as DislikeIcon } from "../../Assets/dislike.svg";
import { ReactComponent as LikedIcon } from "../../Assets/Liked.svg";
import { ReactComponent as RepostedIcon } from "../../Assets/reposted.svg";
import { ReactComponent as DislikedIcon } from "../../Assets/disliked.svg";
import { ReactComponent as DotsIcon } from "../../Assets/dots.svg";
import { ReactComponent as LinkIcon } from "../../Assets/link.svg";
import { ReactComponent as MessageIcon } from "../../Assets/message2.svg";
import { ReactComponent as ScreenshotIcon } from "../../Assets/download.svg";
import { ReactComponent as LocationIcon } from "../../Assets/Location.svg";
import { ReactComponent as BlockIcon } from "../../Assets/user-block.svg";
import Pic2 from "../../Assets/profile/2.jpg";
import Pic3 from "../../Assets/profile/3.jpg";
import Pic4 from "../../Assets/profile/4.jpg";
// import { ReactComponent as RouteIcon } from "../../Assets/navigation.svg";

const CircularIcons = () => {
  const icons = [
    { label: "News", src: Pic2, unread: false },
    { label: "Moments", src: Pic3, unread: false },
    { label: "Activity", src: Pic4, unread: true },
    { label: "Hot Spot", src: Pic2, unread: false },
    { label: "Alert", src: Pic3, unread: true },
    { label: "SOS", src: Pic4 },
  ];

  return (
    <Stack direction="row" style={{ padding: "1rem 2rem", justifyContent: "space-between" }}>
      {icons.map((icon, index) => (
        <Stack key={index} direction="column" alignItems="center" spacing={1}>
          <Avatar
            src={icon.src}
            alt={icon.label}
            style={{
              border: icon.unread ? "3px solid #FF2557" : "3px solid transparent",
              alignItems: "center",
              width: "60px",
              height: "60px",
            }}
          />
          <Typography variant="caption">{icon.label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

const Feed = () => {
  const messageRefs = useRef({});
  const { user } = useOutletContext();
  const [messages, setMessages] = useState([]);
  const [gif, setGif] = useState(null);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openBar, setOpenBar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const open = Boolean(anchorEl);
  const [location, setLocation] = useState({
    lat: "",
    long: "",
  })

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation((prevFormData) => ({
            ...prevFormData,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          }));
        },
        (error) => {
          setMessage("Error getting location. Please enable location services.");
          setOpenBar(true);
        }
      );
    } else {
      setMessage("Geolocation is not supported by this browser.");
      setOpenBar(true);
    }
  }, []);

  const fetchMessages = async () => {
    setLoading(true)
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    try {
      const response = await axios
        .get("https://api.locaided.com/v1/messages/", {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
          params: {
            lat: location.lat,
            long: location.long,
          },
        });
      const formattedMessages = response.data.data.messages.map(message => {
        const dateTimeString = `${message.date} ${message.time}`;
        const messageTime = moment.utc(dateTimeString, "DD.MM.YYYY HH:mm").local();
        const formattedTime = messageTime.fromNow();
        return {
          ...message,
          formattedTime,
        };
      });


      setMessages(formattedMessages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleLike = (id) => {
    if (id !== null) {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .post(
          `https://api.locaided.com/v1/messages/${id}/likes`,
          {},
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((_response) => {
          fetchMessages();
        })
        .catch((error) => console.error("Error Like:", error));
    }
  };

  const handleUnlike = (id) => {
    if (id !== null) {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .delete(
          `https://api.locaided.com/v1/messages/${id}/unlike`,
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((_response) => {
          fetchMessages();
        })
        .catch((error) => console.error("Error Like:", error));
    }
  };

  const handleRepost = (id) => {
    if (id !== null) {
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .post(
          `https://api.locaided.com/v1/messages/${id}/retweet`,
          {},
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((_response) => {
          fetchMessages();
        })
        .catch((error) => console.error("Error Like:", error));
    }
  };

  const handleCommentChange = (id, value) => {
    setDescription((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleClick = async (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedMessageId(id);
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    await axios
      .get(`https://api.locaided.com/v1/messages/${id}`, {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        setSelectedMessage(response.data.data.message)
      })
      .catch((error) => console.error("Error fetching message:", error));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMessageId(null);
    setSelectedMessage(null);
    setOpenBar(false);
  };

  const handleMute = async (id) => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    try {
      const response = await axios.put(
        `https://api.locaided.com/v1/users/${id}/mute`,
        {},
        {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        }
      );
      fetchMessages();
      setSeverity("success");
      setMessage(response.data.message);
      setOpenBar(true);
    } catch (error) {
      setSeverity("error");
      setMessage("Failed to mute the user. Please try again.");
      setOpenBar(true);
    }
  };

  const setMessageRef = (id) => (el) => {
    if (el) messageRefs.current[id] = el;
  };

  const handleSaveImage = (messageId) => {
    const messageRef = messageRefs.current[messageId];

    if (!messageRef) {
      console.error("Message ref not found for ID:", messageId);
      return;
    }

    html2canvas(messageRef, {
      useCORS: true,
      backgroundColor: '#212330'
    })
      .then((canvas) => {
        const link = document.createElement("a");
        link.download = `post-${messageId}-screenshot.png`;
        link.href = canvas.toDataURL("image/png");
        link.click();
      })
      .catch((err) => {
        console.error("Screenshot error:", err);
        setSeverity("error");
        setMessage("Failed to capture screenshot");
        setOpenBar(true);
      });
  };

  const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleGifChange = (e) => setGif(e.target.files[0]);

  const handleSubmit = async (id) => {
    const url = `https://api.locaided.com/v1/messages/${id}/replies`;
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    if (!description.trim()) return;

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        url,
        {
          reply: {
            content: description,
            message_id: id,
            user_id: user.id,
          },
        },
        {
          headers: {
            "access-token": accessToken,
            "token-type": tokenType,
            client: client,
            expiry: expiry,
            uid: uid,
          },
        }
      );
      fetchMessages();
      setDescription("");
    } catch (error) {
      console.error(
        "Error sending Comment:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const renderPreview = () => {
    const hasFiles = image || gif;

    return (
      hasFiles && (
        <Stack spacing={2} direction="row" sx={{ marginTop: "2rem", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {image && (
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              style={{ maxWidth: "15%", maxHeight: "auto", borderRadius: "8px" }}
            />
          )}
          {gif && (
            <img
              src={URL.createObjectURL(gif)}
              alt="GIF Preview"
              style={{ maxWidth: "15%", maxHeight: "auto", borderRadius: "8px" }}
            />
          )}
        </Stack>)
    );
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-start">
        <NavBar user={user} />
        <Grid item xs={12} md={7} pl={0}>
          <Grid container direction="column">
            <Grid item>
              <CircularIcons />
            </Grid>
            <Grid item>
              <PostCreate user={user} />
            </Grid>
            {loading ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <CircularProgress size={40} sx={{ color: "#FD445D" }} />
                <Typography variant="contain" style={{ marginTop: "18px" }}>
                  Loading...
                </Typography>
              </Box>
            ) : (
              <>
                {messages.length > 0 ? (
                  messages.map((message) => (
                    <Grid item key={message.id} mx={1} mt={1} ref={setMessageRef(message.id)}>
                      <Card
                        sx={{
                          maxWidth: 800,
                          width: 800,
                          borderRadius: "12px",
                          padding: "1rem",
                          background: "#212330",
                        }}
                      >
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Box display="flex" alignItems="center">
                            <Avatar
                              src={message.user_details.profile_image.sharable}
                              alt="User"
                              sx={{ marginRight: 2 }}
                            />
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Box display="flex" alignItems="center" gap={0.5}>
                                <span style={{ fontWeight: "bold", fontSize: "13px" }}>
                                  {`${message.user_details.first_name} ${message.user_details.last_name}`}
                                </span>
                                {message.user_details.verified ? (
                                  <img
                                    src={VerifiedIconPng}
                                    alt="Verified"
                                    style={{
                                      width: "13px",
                                      height: "13px",
                                    }}
                                  />
                                ) : (
                                  " "
                                )}
                              </Box>
                              <span style={{ fontSize: "12px", color: "#B3B6C4" }}>
                                {message.formattedTime}
                              </span>
                            </Box>
                          </Box>
                          <IconButton component="span" onClick={(e) => handleClick(e, message.id)} sx={{ color: "#fff" }}>
                            <DotsIcon width="16px" height="16px" />
                          </IconButton>
                        </Box>
                        <Box>
                          <CardContent sx={{ padding: 0, marginTop: "0.5rem" }}>
                            <span style={{ fontSize: "0.75rem" }}>
                              {message.description}
                            </span>
                          </CardContent>
                          {message.image_url.sharable && (
                            <CardMedia
                              component="img"
                              image={message.image_url.sharable}
                              alt="Message Image"
                              sx={{
                                borderRadius: "15px",
                                marginTop: "1rem",
                                maxWidth: "100%",
                              }}
                            />
                          )}

                          <CardActions
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {
                              message.boosted ? <Button size="small" startIcon={<BoostIcon width="20px" height="20px" />}>
                                {message.boost_count}
                              </Button> : <Button size="small" startIcon={<BoostIcon width="20px" height="20px" />}>
                                {message.boost_count}
                              </Button>
                            }
                            {
                              message.liked ? <Button size="small" startIcon={<LikedIcon width="20px" height="20px" />} >
                                {message.like_count}
                              </Button> : <Button size="small" startIcon={<LikeIcon width="20px" height="20px" />} onClick={() => handleLike(message.id)}>
                                {message.like_count}
                              </Button>
                            }
                            {
                              message.retweeted ? <Button size="small" startIcon={<RepostedIcon width="20px" height="20px" />}>
                                {message.retweeted_count}
                              </Button> : <Button size="small" startIcon={<RepostIcon width="20px" height="20px" onClick={() => handleRepost(message.id)} />}>
                                {message.retweeted_count}
                              </Button>
                            }
                            {
                              message.unliked ? <Button size="small" startIcon={<DislikedIcon width="20px" height="20px" />}>
                                {message.unlike_count}
                              </Button> : <Button size="small" startIcon={<DislikeIcon width="20px" height="20px" onClick={() => handleUnlike(message.id)} />}>
                                {message.unlike_count}
                              </Button>
                            }
                          </CardActions>
                        </Box>
                        <Stack direction="row" alignItems="center" spacing={2} mt={1}>
                          <Avatar alt="User" src={user.profile_image_url.sharable} />
                          <TextField
                            placeholder="Write your Comment"
                            size="small"
                            fullWidth
                            value={description[message.id] || ""}
                            onChange={(e) => handleCommentChange(message.id, e.target.value)}
                            multiline
                            minRows={1}
                            maxRows={5}
                            sx={{
                              backgroundColor: "#2A2D3D",
                              lineHeight: "1rem",
                              color: "#D7D9E3",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                                "& textarea::placeholder": {
                                  color: "#D7D9E3",
                                  fontSize: "0.8rem",
                                  opacity: 1,
                                },
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <>

                                  <label>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                      style={{ display: "none" }}
                                    />
                                    <IconButton component="span" sx={{ color: "#fff" }}>
                                      <GalleryIcon width="20px" height="20px" />
                                    </IconButton>
                                  </label>

                                  <label>
                                    <input
                                      type="file"
                                      accept="image/gif"
                                      onChange={handleGifChange}
                                      style={{ display: "none" }}
                                    />
                                    <IconButton component="span" sx={{ color: "#fff" }}>
                                      <GifIcon width="20px" height="20px" />
                                    </IconButton>
                                  </label>

                                  <IconButton
                                    sx={{ backgroundColor: "#ff2557" }}
                                    onClick={() => handleSubmit(message.id)}
                                  >
                                    {description[message.id]?.trim() ? (
                                      <SendIcon width="14px" height="14px" />
                                    ) : (
                                      <MicIcon width="14px" height="14px" />
                                    )}
                                  </IconButton>
                                </>
                              ),
                            }}
                          />
                        </Stack>
                        {renderPreview()}
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Grid item textAlign="center">
                    <Typography variant="h6" color="#fff">
                      No Posts Yet
                    </Typography>
                  </Grid>
                )}
              </>)}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <LiveFeedFilter />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            backgroundColor: "#303343",
            color: "#D7D9E3",
          },
        }}
      >
        <MenuItem onClick={() => console.log(`Copy Link for message ID: ${selectedMessageId}`)}>
          <ListItemIcon>
            <LinkIcon width="18px" height="18px" />
          </ListItemIcon>
          <ListItemText primary="Copy Link" primaryTypographyProps={{
            style: { fontSize: "14px" },
          }} />
        </MenuItem>

        <MenuItem onClick={() => console.log(`message Link for message ID: ${selectedMessage.user_id}`)}>
          <ListItemIcon>
            <MessageIcon width="18px" height="18px" />
          </ListItemIcon>
          <ListItemText primary="Message" primaryTypographyProps={{
            style: { fontSize: "14px" },
          }} />
        </MenuItem>

        <MenuItem onClick={() => handleSaveImage(selectedMessageId)}>
          <ListItemIcon>
            <ScreenshotIcon width="18px" height="18px" />
          </ListItemIcon>
          <ListItemText primary="Screenshot Post" primaryTypographyProps={{
            style: { fontSize: "14px" },
          }} />
        </MenuItem>

        <MenuItem onClick={() => console.log(`location Link for message ID: ${selectedMessageId}`)}>
          <ListItemIcon>
            <LocationIcon width="18px" height="18px" />
          </ListItemIcon>
          <ListItemText primary="Ask for Location" primaryTypographyProps={{
            style: { fontSize: "14px" },
          }} />
        </MenuItem>

        <MenuItem onClick={() => handleMute(selectedMessage.user_id)}>
          <ListItemIcon>
            <BlockIcon width="18px" height="18px" />
          </ListItemIcon>
          <ListItemText primary="Mute User" primaryTypographyProps={{
            style: { fontSize: "14px" },
          }} />
        </MenuItem>
      </Menu>
      <Snackbar
        open={openBar}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Feed;
