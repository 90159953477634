/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    InputBase,
    InputAdornment,
    Paper,
    Typography,
    Box,
    IconButton,
    CircularProgress,
    Menu,
    MenuItem,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../Assets/search.svg";
import { ReactComponent as FireIcon } from "../../../Assets/Fire.svg";
import { ReactComponent as LogoIcon } from "../../../Assets/Ellipse.svg";
import { ReactComponent as DotsIcon } from "../../../Assets/dots.svg";
import { ReactComponent as BlockIcon } from "../../../Assets/user-block.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/delete-02.svg";

const AllLocation = () => {
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const open = Boolean(anchorEl);
    const { onSelectChat } = useOutletContext();
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [reqLoading, setReqLoading] = useState(false);
    const navigate = useNavigate();

    const handleSearch = async () => {
        if (!address.trim()) {
            alert("Please enter an address.");
            return;
        }

        const apiKey = "97558d57bf1e41539292a9683a6f19c2";
        const geocodeUrl = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
            address
        )}&key=${apiKey}`;

        try {
            const response = await fetch(geocodeUrl);
            const data = await response.json();
            if (data.status.code === 200 && data.results.length > 0) {
                const lat = data.results[0].geometry.lat;
                const lng = data.results[0].geometry.lng;
                setCoordinates({ lat, lng });
                setOpenDialog(true)
            } else {
                alert("Address not found. Try another.");
                setCoordinates(null);
            }
        } catch (error) {
            alert("Error fetching coordinates. Please try again later.");
            setCoordinates(null);
        }
    };

    const handleSubmit = async () => {
        setReqLoading(true);
        const url = `https://api.locaided.com/v1/groups/create_location_group`;
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        const formData = new FormData();
        formData.append("name", address);
        formData.append("lat", coordinates.lat);
        formData.append("long", coordinates.long);

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            navigate("/dashboard/chats/location");
            fetchChats()
            setReqLoading(false);
            handleClose();
        } catch (error) {
            console.error(
                "Error sending group creation data:",
                error.response ? error.response.data : error.message
            );
            setReqLoading(false);
        }
    };

    const fetchChats = () => {
        const accessToken = localStorage.getItem("access-token");
        const tokenType = localStorage.getItem("token-type");
        const client = localStorage.getItem("client");
        const expiry = localStorage.getItem("expiry");
        const uid = localStorage.getItem("uid");

        axios
            .get("https://api.locaided.com/v1/users/get_all_chats?type=location", {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            })
            .then((response) => {
                setChats(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching chats:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchChats();
    }, []);

    const handleClick = async (event, id) => {
        setAnchorEl(event.currentTarget);
        setDeleteId(id);
    };

    const handleDelete = async () => {
        try {
            const accessToken = localStorage.getItem("access-token");
            const tokenType = localStorage.getItem("token-type");
            const client = localStorage.getItem("client");
            const expiry = localStorage.getItem("expiry");
            const uid = localStorage.getItem("uid");
            const response = await axios.delete(`https://api.locaided.com/v1/groups/${deleteId}`, {
                headers: {
                    "access-token": accessToken,
                    "token-type": tokenType,
                    client: client,
                    expiry: expiry,
                    uid: uid,
                },
            });
            handleClose();
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || error.message || "An error occurred";
            console.log(errorMessage, "error");
        }
    };

    const handleClose = () => {
        fetchChats();
        setAnchorEl(null);
        setDeleteId(null);
        setCoordinates(null);
        setAddress("")
        setOpenDialog(false)
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CircularProgress size={50} sx={{ color: "#FD445D" }} />
                <Typography variant="h6" style={{ marginTop: "18px" }}>
                    Loading...
                </Typography>
            </Box>
        );
    }
    return (
        <>
            <Box sx={{
                pt: 1,
                backgroundColor: "#212330",
                px: "1rem",
                minHeight: '87vh',
                borderRadius: "10px",
            }}>
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                    <Paper
                        style={{
                            padding: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            margin: "10px auto",
                            borderRadius: "25px",
                        }}
                    >
                        <InputBase
                            style={{ marginLeft: 8, flex: 1 }}
                            placeholder="Input address to find or create chat"
                            inputProps={{ "aria-label": "search" }}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </Paper>
                </Box>
                <Box>
                    {chats.length === 0 ? (
                        <Box sx={{
                            mt: 2,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            gap: 1
                        }}>
                            <LogoIcon width="32px" height="34px" style={{ marginBottom: "2px" }} />
                            <Box alignItems="flex-start" maxWidth="30%">
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Start a Chat by Address
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    No Admin, no Settings. Just chat.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Enter any address in the search field to create or join a chat. Anyone searching the same location can participate.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Dive into community conversations centered around specific places.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Your presence remains invisible until you send a message. If you stay inactive for 48 hours without sending a message, you will be removed from the chat.
                                </Typography>
                                <Typography
                                    variant="body2"
                                    p={1}
                                    mb={1}
                                    sx={{
                                        border: "1px solid #E6E6E6",
                                        borderRadius: "0 16px 16px 16px",
                                        fontSize: "13px",
                                    }}
                                >
                                    Stay Active in the Chat: Your presence in this location-based chat is timed. Before your timer expires, use coins to extend your stay and keep participating. Don’t let time run out on your conversations!
                                </Typography>
                                <Box sx={{ mb: 1, border: "1px solid #E6E6E6", borderRadius: "0 16px 16px 16px", paddingRight: "5px", display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="body2"
                                        p={1}
                                        sx={{
                                            fontSize: "13px",
                                        }}
                                    >
                                        Enough reading… let’s get chatting! <b>Send your first message to open the chat</b> and make it memorable!
                                    </Typography>
                                    <FireIcon width="26%" height="26%" />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <List>
                            <Box sx={{ backgroundColor: "#303343", borderRadius: "10px" }}>
                                {chats.map((chat, index) => (
                                    <div key={index}>
                                        <ListItem button onClick={() => onSelectChat(chat)}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={chat.group.name}
                                                    src={chat.group.group_image_url?.sharable}
                                                    style={{ width: "40px", height: "40px" }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={chat.group.name}
                                                style={{ fontSize: "16px" }}
                                            />
                                            <IconButton component="span" onClick={(e) => {
                                                e.stopPropagation();
                                                handleClick(e, chat.group.id);
                                            }} sx={{ color: "#fff" }}>
                                                <DotsIcon width="16px" height="16px" />
                                            </IconButton>
                                        </ListItem>
                                    </div>
                                ))}
                            </Box>
                        </List>
                    )}
                </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    sx: {
                        borderRadius: "8px",
                        backgroundColor: "#BEC0C9",
                        color: "#000",
                    },
                }}
            >
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon width="18px" height="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Leave and Delete Chat" primaryTypographyProps={{
                        style: { fontSize: "14px" },
                    }} />
                </MenuItem>

                <MenuItem onClick={() => console.log("block click")}>
                    <ListItemIcon>
                        <BlockIcon width="18px" height="18px" />
                    </ListItemIcon>
                    <ListItemText primary="Report this Group Chat" primaryTypographyProps={{
                        style: { fontSize: "14px", color: "#FF2557" },
                    }} />
                </MenuItem>
            </Menu>
            {coordinates && (
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    sx={{
                        maxWidth: "400px",
                        margin: "auto",
                        "& .MuiPaper-root": {
                            backgroundColor: "#BEC0C9",
                            color: "#212330",
                            padding: "15px",
                            borderRadius: "15px",
                        },
                        "& .MuiDialogTitle-root": {
                            padding: "5px",
                            color: "#212330",
                            textAlign: "center",
                        },
                        "& .MuiDialogContentText-root": {
                            textAlign: "center",
                            color: "#212330",
                        },
                        "& .MuiDialogContent-root": {
                            padding: "5px",
                        },
                        "& .MuiDialogActions-root": {
                            padding: "5px",
                        },
                        "& .MuiDialog-container": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        },
                    }}
                >
                    <DialogTitle fontWeight="bold">Confirm Location Group</DialogTitle>
                    <DialogContent>
                        <DialogContentText fontSize="13px" fontFamily="Helvetica">
                            Address: {address} <br />
                            Latitude: {coordinates.lat} <br />
                            Longitude: {coordinates.lng}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                            width: "100%",
                        }}
                    >
                        <Button
                            onClick={handleSubmit}
                            disabled={reqLoading}
                            sx={{
                                flex: 1,
                                backgroundColor: "#4caf50",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#45a049" },
                            }}
                        >
                            {reqLoading ? "Creating..." : "Create"}
                        </Button>
                        <Button
                            onClick={handleClose}
                            sx={{
                                flex: 1,
                                backgroundColor: "transparent",
                                color: "#212330",
                                "&:hover": { backgroundColor: "#A6A6A6", color: "#212330" },
                            }}
                        >
                            <b>Cancel</b>
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default AllLocation;
