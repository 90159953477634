import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTheme } from "../../ThemeContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";

const AddContact = () => {
  const { user } = useOutletContext();
  const { darkMode } = useTheme();
  const navigate = useNavigate();
  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: "0" }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <IconButton onClick={() => navigate("/dashboard/contacts")}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" ml={2}>
          Add Contact
        </Typography>
      </Box>

      <Typography variant="h5" mt={8}>
        QR Code
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
          mt: 4,
        }}
      >
        <img
          src={user.qr_png?.sharable}
          alt="Example"
          style={{
            padding: 5,
            width: "40%",
            height: "auto",
            borderRadius: "20px",
            border: "3px solid",
            borderColor: darkMode ? "#fff" : "#000",
          }}
        />
        <Typography variant="h6" mt={2}>
          {user.username}
        </Typography>
        <Button
          startIcon={<QrCodeScannerOutlinedIcon />}
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 5,
            padding: "5px 20px 5px 20px",
            borderRadius: "50px",
            border: "2px solid",
            borderColor: darkMode ? "#fff" : "#000",
            textTransform: "none",
            "&:hover": {
              color: "#fff",
              backgroundColor: "#e0224f",
              border: "none",
            },
          }}
        >
          Share QR Code and connect with friends
        </Button>
      </Box>
    </Box>
  );
};

export default AddContact;
