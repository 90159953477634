import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  // Switch,
  Link,
  Divider,
} from "@mui/material";
// import { useTheme } from "../../ThemeContext";
import { useNavigate, useOutletContext } from "react-router-dom";
// import Brightness6OutlinedIcon from "@mui/icons-material/Brightness6Outlined";
import { ReactComponent as NotificationIcon } from "../../Assets/notification.svg";
import { ReactComponent as PremiumIcon } from "../../Assets/checkmark.svg";
import { ReactComponent as ContactsIcon } from "../../Assets/user-account.svg";
import { ReactComponent as ReportIcon } from "../../Assets/report.svg";
import { ReactComponent as AboutIcon } from "../../Assets/about.svg";
import { ReactComponent as TermsIcon } from "../../Assets/terms.svg";
import { ReactComponent as PrivacyIcon } from "../../Assets/user-shield.svg";
import { ReactComponent as DeactivateIcon } from "../../Assets/user-remove.svg";

const UserSettings = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useOutletContext();
  // const { darkMode, toggleTheme } = useTheme();

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List >
        <ListItem
          button
          onClick={() => navigate("/dashboard/additional_settings")}
        >
          <ListItemIcon>
            <NotificationIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PremiumIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Locaided Premium" />
        </ListItem>
        <ListItem button onClick={() => navigate("/dashboard/contacts")}>
          <ListItemIcon>
            <ContactsIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Contacts" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ReportIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Report Red Flag" />
        </ListItem>
        <ListItem button onClick={() => navigate("/dashboard/about_app")}>
          <ListItemIcon>
            <AboutIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="About the App" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <TermsIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Terms and Conditions" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <PrivacyIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Privacy and Safety" />
        </ListItem>
        <ListItem
          style={{ marginBottom: "1rem" }}
          button
          onClick={() => navigate("/dashboard/deactivate_account")}
        >
          <ListItemIcon>
            <DeactivateIcon width="22px" height="22px" />
          </ListItemIcon>
          <ListItemText primary="Deactivate your account" />
        </ListItem>
        {/* <ListItem>
          <ListItemIcon>
            <Brightness6OutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Dark Theme" />
          <Switch edge="end" checked={darkMode} onClick={toggleTheme} />
        </ListItem> */}
        <Divider sx={{ bgcolor: "#EEEEEE", height: 1, mx:1 }} />

        <ListItem button style={{ marginTop: "1rem", }}>
          <Link underline="none" fontWeight="bold">Upgrade Account</Link>
        </ListItem>
        <ListItem button style={{ marginBottom: "1rem" }} onClick={handleClickOpen}>
          <Link underline="none" fontWeight="bold">Logout {user.username}</Link>
        </ListItem>
      </List>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#212121",
            color: "#fff",
            padding: "15px",
          },
          "& .MuiDialogTitle-root": {
            padding: "5px",
          },
          "& .MuiDialogContentText-root": {
            color: "#fff",
          },
          "& .MuiDialogContent-root": {
            padding: "5px",
          },
          "& .MuiDialogActions-root": {
            padding: "5px",
          },
        }}
      >
        <DialogTitle>Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#A6A6A6",
              color: "#fff",
              "&:hover": { backgroundColor: "#B8B8B8" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogout}
            sx={{
              backgroundColor: "#FF2557",
              color: "#fff",
              "&:hover": { backgroundColor: "#FF2557" },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserSettings;
