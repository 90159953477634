import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
  Typography,
  Avatar,
  Tooltip,
  styled,
} from "@mui/material";
import { useTheme } from "../../ThemeContext";
import { ReactComponent as CanvasIcon } from '../../Assets/canvas.svg';
import { ReactComponent as HomeIcon } from '../../Assets/home.svg';
import { ReactComponent as ContactIcon } from '../../Assets/user-account.svg';
import { ReactComponent as MessageIcon } from '../../Assets/message.svg';
import { ReactComponent as FlashIcon } from '../../Assets/flash.svg';
import { ReactComponent as SaveIcon } from '../../Assets/save.svg';
import { ReactComponent as CheckMarkIcon } from '../../Assets/checkmark.svg';
import { ReactComponent as SettingIcon } from '../../Assets/Setting.svg';
import { ReactComponent as ProfileIcon } from '../../Assets/user.svg';
import lightLogo from "../../Assets/white logo.png";
import darkLogo from "../../Assets/white logo dark.png";
import CanvasPic from "../../Assets/canvas.png";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#EFEFF4",
    color: "#212121",
    padding: "10px 15px",
    borderRadius: "15px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
});

const SideMenu = () => {
  const { darkMode } = useTheme();
  return (
    <Grid sx={{
      width: 280,
      height: 500,
      borderRadius: "12px",
      backgroundColor: "#212330",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    }}>
      <Box>
        <List sx={{ width: "100%", alignItems: "center", padding: "1rem", color: "#BEC0C9" }}>
          <ListItem
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={darkMode ? lightLogo : darkLogo}
              alt="Locaided"
              style={{
                width: "70%",
                height: "auto",
                margin: "1rem 0",
              }}
            />
          </ListItem>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <HomeIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/dashboard/contacts">
            <ListItemIcon>
              <ContactIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Contacts" />
          </ListItem>
          <ListItem button component={Link} to="/dashboard/chats">
            <ListItemIcon>
              <MessageIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Messages" />
          </ListItem>
          <ListItem button component={Link} to="/dashboard/flash_point">
            <ListItemIcon>
              <FlashIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Flash Point" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <SaveIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Bookmarked" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <CheckMarkIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Premium" />
          </ListItem>
          <ListItem button component={Link} to="/dashboard/settings">
            <ListItemIcon>
              <SettingIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button component={Link} to="/dashboard/profile">
            <ListItemIcon>
              <ProfileIcon width="20px" height="20px" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
        </List>
      </Box>
      <CustomTooltip
        title={
          <>
            <Typography variant="subtitle1" color="#000" fontWeight="bold">
              Craft Your Comfort Zone
            </Typography>
            <Typography variant="contain" color="#000">
              Upload a favorite image (ideal size: 222x4444) that stays with you as you browse. To set it, simply click anywhere on the canvas to open your gallery. This personal touch enhances your interactions without being stored on our servers, ensuring your privacy.
            </Typography>
          </>
        }
        placement="right-start"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [50, -150],
              },
            },
          ],
        }}
      >
        <Box
          sx={{
            mt: 1,
            width: 280,
            borderRadius: "10px",
            padding: 2,
            backgroundColor: "#212330",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            cursor: "pointer"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              ml: 2
            }}
          >
            {/* Personal Canvas */}
            <Typography variant="h6" component="div" color="#F0F1F5" pr={1}>
              Personal Canvas
            </Typography>
            <CanvasIcon width="20px" height="20px" />
          </Box>

          {/* Avatar or Image */}
          <Avatar
            alt="Canvas Avatar"
            src={CanvasPic}
            sx={{
              width: 220,
              height: 415,
              alignSelf: "center",
              my: 2,
              borderRadius: "12px",
            }}
          />
        </Box>
      </CustomTooltip>
    </Grid>
  );
};

export default SideMenu;
