import React from 'react'
import {
  Grid
} from "@mui/material";
import { Outlet, useOutletContext } from "react-router-dom";
import LiveFeedFilter from "../Layout/LiveFeedFilter"
import NavBar from "./NavBar";

const FlashPoint = () => {
  const { user } = useOutletContext();

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      <NavBar user={user} />
      <Grid item xs={12} md={7}>
        <Outlet context={{ user }}/>
      </Grid>
      <Grid item xs={12} md={3} pl={0}>
        <LiveFeedFilter />
      </Grid>
    </Grid>
  )
}

export default FlashPoint